import Vue from 'vue'
import Vuex from 'vuex'
import menuStore from './menuStore'
import userStore from './userStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      icon: 'https://www.qiuxs.cn/sharing-tools/img/read/2a5245db877d6f15e063f40ce1973e58',
      title: 'Fast Cms'
  },
  getters: {
    icon(state) {
      return state.icon;
    },
    title(state) {
      return state.title;
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    menuStore,
    userStore
  }
})
